@import "./src/resources/scss/index";

.pixel-board-tooltip__arrow {
  width: 0;
  height: 0;
  display: inline-block;
  position: absolute;
  top: 7px;

  &--left {
    border-top: 5px solid transparent;
    border-left: 5px solid $color-main-accent;
    border-bottom: 5px solid transparent;
    right: -6px;
  }

  &--right {
    border-top: 5px solid transparent;
    border-right: 5px solid $color-main-accent;
    border-bottom: 5px solid transparent;
    left: -6px;
  }
}
