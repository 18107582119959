.navigation-link {
  color: #0b9bb7;
  text-decoration: none;
  transition: color 0.25s;

  &--active {
    color: #0dcaf0;
  }

  &:hover {
    color: #0dcaf0;
  }
}
