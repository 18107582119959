@font-face {
    font-family: 'EBGaramond';
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/eb_garamond/EBGaramond-Regular.ttf") format('truetype');
}

@font-face {
    font-family: 'EBGaramond';
    font-style: normal;
    font-weight: 700;
    src: url("../fonts/eb_garamond/EBGaramond-Bold.ttf") format('truetype');
}

@font-face {
    font-family: 'RobotoSlab';
    font-style: normal;
    font-weight: 300;
    src: url("../fonts/roboto_slab/RobotoSlab-Light.ttf") format('truetype');
}

@font-face {
    font-family: 'RobotoSlab';
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/roboto_slab/RobotoSlab-Regular.ttf") format('truetype');
}

@font-face {
    font-family: 'RobotoSlab';
    font-style: normal;
    font-weight: 700;
    src: url("../fonts/roboto_slab/RobotoSlab-Bold.ttf") format('truetype');
}

@import "main";

$color-main-accent: $cyan-400;
$color-main-accent-light: $cyan-600;
$color-secondary-accent: #fe9300;
$color-secondary-accent-light: #faad41;

body {
    scroll-behavior: smooth;
    font-family: RobotoSlab, $font-family-sans-serif;
    transition: all 0.25s;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: EBGaramond, $font-family-sans-serif;
}

a {
    color: $color-main-accent-light;
    text-decoration: none;
    transition: color 0.25s;

    &:hover {
        color: $color-main-accent;
    }
}


#root {
    max-width: 1000px;
    background-color: white;
}

.pixel-board {
    height: 1000px;
    width: 1000px;
    background-image: url('../../../public/img/pixel-unit-background.gif');
}

.text-main-accent {
    color: $color-main-accent-light;

    &-light {
        color: $color-main-accent;
    }

    &-gray {
        color: $gray-600;
    }
}

.text-secondary-accent {
    color: $color-secondary-accent;

    &-light {
        color: $color-secondary-accent-light;
    }
}

.bg-main-accent {
    background-color: $color-main-accent-light;

    &-light {
        background-color: $color-main-accent;
    }
}

.bg-secondary-accent {
    background-color: $color-secondary-accent;

    &-light {
        background-color: $color-secondary-accent-light;
    }
}


